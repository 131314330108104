import Header from "./Header";
import Home from "./Home";
import Footer from "./Footer";
import AboutUs from "./AboutUs";
import Prices from "./Prices";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <>
      <div style={{ zIndex: 120 }}>
        <Header />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="prices" element={<Prices />} />
            <Route path="about-us" element={<AboutUs />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
    </>
  );
}

export default App;

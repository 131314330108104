import React from "react";
import "../styles/Home.css";
const Home = () => {
  return (
    <>
      <div
        style={{
          background:
            "no-repeat center center/1920px fixed url('/assets/BMW.jpg')",
          height: "100vh",
          width: "100vw",
          position: "sticky",
        }}
      >
        <div className="testimonialText">
          <h1
            className="ui huge center aligned header inverted"
            style={{
              fontFamily: "Open Sans, sans-serif",
              fontFamily: "Roboto Mono, monospace",
              fontSize: "70px",
            }}
          >
            Welcome to Shiny Wheels
          </h1>
          <h1 className="ui big center aligned header inverted">
            One of the best choices for cleaning your car, and keeping your
            Wheels Shiny
          </h1>
          <h1 className="ui big center aligned header inverted">
            <a href="/prices">
              <button class="ui inverted purple basic button huge">
                <h2>Check our Prices</h2>
              </button>{" "}
            </a>
          </h1>
        </div>
      </div>
      <div className="ui grid wrappable">
        <div className="eight wide column">
          <div className="testimonialText">
            <h1
              className="ui inverted centered header"
              style={{ fontSize: "50px" }}
            >
              First In class experience
            </h1>
            <h2 className="ui inverted centered header">
              Some of the best Experienced detailing Technicians working to make
              your car as clean and Shiny as possible
            </h2>
          </div>
        </div>
        <div className="eight wide column">
          <img
            src="/assets/Wheel.jpg"
            alt="A wheel being cleaned"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>
      <div className="ui grid">
        <div
          className="eight wide column"
          style={{
            background:
              "no-repeat center center/200% url('/assets/CustomerService.jpg')",
            height: "100vh",
            width: "50%",
          }}
        ></div>
        <div className="eight wide column right-column">
          <div className="testimonialText">
            <h1
              className="ui inverted centered header"
              style={{ fontSize: "50px" }}
            >
              Great customer experience
            </h1>
            <h2 className="ui inverted centered header">
              Your car will be detailed in a timely and professional manner to
              your satisfaction
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

import React from "react";

const AboutUs = () => {
  return (
    <div className="ui container">
      <div className="ui segment">
        <h1 className="ui centered header">
          We Are A Team of Expert Detailers looking to help provide you with
          excellent and fast detailing experience
        </h1>
        <h3>
          Our Team is comprised of a couple of Self taught Techicians that are
          the the ones holding up our business. They Are comprised of
          Technicians that focus on Interior Detailing, Exterior Detailing,
          Buffing, and a Lead. Each dedicated on their own task, we provide an
          exceptional service that makes sure every part of your car is being
          cleaned in a satisfactory and professional manner.{" "}
        </h3>
        <div className="ui image">
          <img
            src="assets/EmployeeExample.jpg"
            style={{
              maxWidth: "70%",
              height: "auto",
              margin: "auto",
              borderRadius: "5px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

import React, { useState } from "react";
import "../styles/Prices.css";

const Prices = () => {
  const [hovered, setHovered] = useState(-1);

  const onHover = (num) => {
    setHovered(num);
  };

  return (
    <div
      className="ui container"
      style={{ marginTop: "3em", marginBottom: "3em" }}
    >
      <div className="ui three column grid" onMouseLeave={() => onHover(-1)}>
        <div className="column">
          <div
            className={hovered === 0 ? "ui fluid card open" : "ui fluid card"}
            onMouseEnter={() => onHover(0)}
          >
            <div className="image">
              <img
                src="/assets/Wheel.jpg"
                alt="representing medium tier service"
              />
            </div>
            <div className="content">
              <h1 className="header">Standard Package $159.99</h1>
              {hovered === 0 && (
                <p>
                  <h3>
                    <ul>
                      <li>Car Wash</li>
                      <li>Carpet</li>
                      <li>Seats</li>
                      <li>Doors</li>
                      <li>Dashboard</li>
                      <li>Roof</li>
                      <li>Trunk</li>
                      <li>Engine</li>
                      <li>Wheels</li>
                    </ul>
                  </h3>
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="column">
          <div
            className={hovered === 1 ? "ui fluid card open" : "ui fluid card"}
            onMouseEnter={() => onHover(1)}
          >
            <div className="image ">
              <img
                src="/assets/Wheel2.jpg"
                alt="representing high tier service"
              />
            </div>
            <div className="content">
              <h1 className="header">Golden Package $179.99</h1>
              {hovered === 1 && (
                <h3>
                  <ul>
                    <li>Includes all of the previous Package</li>
                    <li>Undercarriage</li>
                    <li>Wheel Coating</li>
                    <li>Interior leather coating</li>
                    <li>Scent packs</li>
                  </ul>
                </h3>
              )}
            </div>
          </div>
        </div>
        <div className="column">
          <div
            className={hovered === 2 ? "ui fluid card open" : "ui fluid card"}
            onMouseEnter={() => onHover(2)}
          >
            <div className="image">
              <img
                src="/assets/Buffing.jpg"
                alt="representing heighest tier service"
              />
            </div>
            <div className="content">
              <h1 className="header">Shiny Package $199.99</h1>
              {hovered === 2 && (
                <p>
                  <h3>
                    <ul>
                      <li>Includes all of the previous Package</li>
                      <li>Headlight Buffing</li>
                      <li>mirrors moisture protection</li>
                      <li>10% discount on next visit</li>
                    </ul>
                  </h3>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prices;

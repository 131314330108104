import React from "react";

const Header = () => {
  console.log(window.location.pathname);
  return (
    <div
      className="ui secondary pointing inverted menu"
      style={{
        position: "sticky",
        top: 0,
        zIndex: "250",
        backgroundColor: "rgb(27, 28, 29)",
        fontFamily: "Open Sans, sans-serif",
        fontFamily: "Roboto Mono, monospace",
        marginBottom: "0",
      }}
    >
      <h3 className="item">Shiny Wheels</h3>
      <a
        className={window.location.pathname === "/" ? "active item" : "item"}
        href="/"
      >
        <h3>Home</h3>
      </a>
      <a
        className={
          window.location.pathname === "/prices" ? "active item" : "item"
        }
        href="/prices"
      >
        <h3>Prices</h3>
      </a>
      <a
        className={
          window.location.pathname === "/about-us" ? "active item" : "item"
        }
        href="/about-us"
      >
        <h3>About us</h3>
      </a>
      <div className="right menu">
        <a
          className="item"
          href="https://goo.gl/maps/8LCSEciyVaWPo8Fp9"
          target="_blank"
          rel="noreferrer"
        >
          <h3>Get Directions</h3>
        </a>
      </div>
    </div>
  );
};

export default Header;
